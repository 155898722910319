<template>
  <div class="bigDiv">
    <img src="../../assets/images/map/map/01@1.25x.jpg" alt="">
    <img src="../../assets/images/map/map/03.jpg" alt="">

    <img src="../../assets/images/map/map/02@1.25x.jpg" alt="">
    <img src="../../assets/images/map/map/09.jpg" alt="">

    <img src="../../assets/images/map/map/03@1.25x.jpg" alt="">
    <img src="../../assets/images/map/map/05.jpg" alt="">
    <img src="../../assets/images/map/map/06.jpg" alt="">
    <img src="../../assets/images/map/map/07.jpg" alt="">
    <img src="../../assets/images/map/map/08.jpg" alt="">
    <img src="../../assets/images/map/map/08.jpg" alt="">

    <img src="../../assets/images/map/map/04.jpg" alt="" style="margin-bottom: 80px;">
    <img src="../../assets/images/map/btoImg.gif" alt="" class="fixd" @click="FxShow=true">
    <div class="model" :style="{height: innerHeight + 'px', width: innerWidth + 'px'}" ref="mode" v-if="FxShow" @click="FxShow = false">
      <img src="../../assets/images/fx.png" alt="">

    </div>
  </div>
</template>

<script>
import { getWechatConfig } from "@/api/yangsheng";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      innerWidth: "",
      FxShow: false,
      innerHeight: "",
    }
  },
  methods: {
    getWechatConfig () {

      const query = {
        company_id: 1,
        url: window.location.href,
      };
      getWechatConfig(query).then((res) => {
        const agent = res.data.data;
        if (res.data.code === 200) {
          this.$nextTick(() => { });
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature, // 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData,'onMenuShareAppMessage",
              "onMenuShareTimeline",
            ], // 必填，需要使用的JS接口列表
          });

          this.share();
        }
      });
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: "健康知识地图", // 分享标题
            link: "https://kf.hshwhkj.com/map", //这里可带参数，必须是同配置的JS安全域名一致
            desc: "健康知识地图", // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { },
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: "健康知识地图", // 分享标题
            link: "https://kf.hshwhkj.com/map", //这里可带参数，必须是同配置的JS安全域名一致
            desc: "健康知识地图", // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { },
          });
        });
        wx.error((res) => {
          console.log("微信分享错误信息：", res);
        });
      });
    },
  },
  mounted () {
    this.getWechatConfig()
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    console.log(this.innerWidth, this.innerHeight);

  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>           

<style scoped lang='less'>
.bigDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  .fixd {
    position: fixed;
    bottom: 0px;
    width: 100%;
    object-fit: contain;
  }
  .model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 667px;
    background-color: rgba(0, 0, 0, 0.5);
    img {
      width: 240px;
      position: absolute;
      right: 15px;
      top: 0;
    }
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    display: flex;
    flex-direction: column;
    width: 750px;
    margin: 0 auto;
    .model {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translate(-50%);
      height: 667px;
      background-color: rgba(0, 0, 0, 0.5);
      img {
        width: 240px;
        position: absolute;
        top: 0;
        left: 55%;
      }
    }
    .fixd {
      position: fixed;
      bottom: 0px;
      width: 750px;
      object-fit: contain;
    }
  }
}
</style>